import React from "react";
import Paragraph from "../../../utils/Paragraph";
import { AiFillCheckCircle } from "@react-icons/all-files/ai/AiFillCheckCircle";
import BangladeshMap from "./BangladeshMap";
import NepalMap from "./NepalMap";
export default function BannerIndia() {
  const supClass =
    "text-white hover:text-blue transition-all duration-300 ease";
  return (
    <div className="bg-darkblue w-full overflow-hidden lg:min-h-[calc(100vh-5.5rem)] lg:h-auto lg:rounded-b-[80px] rounded-b-[20px] lg:px-28 md:px-12 px-4 flex items-center lg:py-12 py-0">
      <div className="grid lg:grid-cols-[1.25fr_0.75fr] grid-cols-1 auto-rows-auto lg:gap-12 gap-8 w-full h-full lg:py-0 py-8 justify-center items-center my-auto">
        <div className="w-full h-full flex flex-col justify-center">
          <h1 className="lg:text-[45px] md:text-3xl text-2xl text-white font-bold font-body [&>span]:text-[#19BBF9] lg:leading-tight leading-tight">
            Internal Migration:
            <br /> <span>Factual Overview</span>
          </h1>
          <div className="relative grid grid-cols-1 auto-rows-min w-full gap-0 mt-4">
            <TextBox>
              <strong>No. of internal migrants</strong>: 140 million internal
              migrants move for work
              <sup>
                <a
                  href="https://link.springer.com/article/10.1007/s41027-020-00293-8"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [1]
                </a>
              </sup>
            </TextBox>
            <TextBox>
              62% migrants move from rural to rural areas followed by 20%
              migrating from rural to urban
              <sup>
                <a
                  href="https://www.knomad.org/sites/default/files/2021-02/Policy%20Brief%20-%20Internal%20Migrationand%20Urbanization%20-%20India%20Policy%20Brief%2012%20Feb%202021.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [2]
                </a>
              </sup>
            </TextBox>
            <TextBox>
              <strong>
                <u>Key destination areas:</u>
              </strong>{" "}
              <sup>
                <a
                  href="https://prsindia.org/theprsblog/migration-in-india-and-the-impact-of-the-lockdown-on-migrants"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [3]
                </a>
              </sup>{" "}
              Maharashtra, Delhi and Gujarat
            </TextBox>

            <TextBox>
              <strong>
                Prominent sectors of employment for short-term internal migrants
                <sup>
                  <a
                    href="https://www.mospi.gov.in/sites/default/files/publication_reports/533_final.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={supClass}
                  >
                    [4]
                  </a>
                </sup>
                :
              </strong>{" "}
              construction sector, agriculture and manufacturing sector
            </TextBox>
            <TextBox>
              The Global Index Risk Report (2021) placed India at{" "}
              <strong>Rank 7</strong>
              <sup>
                <a
                  href="https://www.germanwatch.org/en/19777"
                  target="_blank"
                  rel="noreferrer"
                  className={supClass}
                >
                  [5]
                </a>
              </sup>
            </TextBox>
            <TextBox>
              <span>
                There are 14 million
                <sup>
                  <a
                    href="https://actionaid.org/sites/default/files/publications/ActionAid%20CANSA%20-%20South%20Asia%20Climate%20Migration%20report%20-%20Dec%202020_3.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={supClass}
                  >
                    [6]
                  </a>
                </sup>{" "}
                6 estimated climate migrants in the country. An estimated 45
                million people are predicted to migrate by 2050 as a result of
                climate change
                <sup>
                  <a
                    href="https://cansouthasia.net/wp-content/uploads/2021/02/Migration_India_20_02_2021.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={supClass}
                  >
                    [7]
                  </a>
                </sup>
              </span>
            </TextBox>
            <TextBox>
              Seasonal migration is a primary coping strategy for a large rural
              population faced with agricultural distress. This is driven by a
              combination of economic and climate-related factors.
            </TextBox>
            <TextBox>
              Climate change impacts are experienced in destination areas as
              well. Migrants at destination locations usually reside in areas
              which are already prone to climate related impacts
            </TextBox>
            <TextBox>
              Portability constraints i.e., the inability to access government
              benefits at destinations continue to impact access to social
              security for internal migrants
            </TextBox>
            <p className="text-white text-sm">
              For more on internal migration in India and other countries in
              South and Southeast Asia, read our report{" "}
              <a
                href="/internal-migration-in-asia.pdf"
                target="_blank"
                rel="noreferrer"
                className={supClass + " text-white underline"}
              >
                Internal Migration in Asia
              </a>
              .
            </p>
          </div>
        </div>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <img
            src="/images/Migration Map_India.png"
            alt="migration map india"
            className="w-full h-full object-contain"
          />
          {/* <NepalMap />
          <div className="flex flex-row items-center gap-3">
            <MapLabelBox color="bg-[#ee6b6e]" title="MRC District" />
            <MapLabelBox color="bg-[#19bbf9]" title="MRC Districts" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
const MapLabelBox = ({ title, color }) => {
  return (
    <div className="flex items-center gap-2">
      <div className={`${color} w-4 h-4`} />
      <p className="text-sm text-white font-body mb-0 leading-tight mt-[0.5px] font-semibold">
        {title}
      </p>
    </div>
  );
};
export const TextBox = (props) => {
  return (
    <div className="flex flex-row justify-start items-start transition-all ease duration-300 group gap-1 md:pb-4">
      {/* <BiSquareRounded className={IconClassName} /> */}

      <AiFillCheckCircle className="md:text-2xl text-xl text-blue shrink-0" />
      <Paragraph className={`${props.className} !text-white ml-2`}>
        {props.children}
      </Paragraph>
    </div>
  );
};
