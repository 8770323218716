import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import RegionMap from "../components/Pages/Region/RegionMap";
import BannerIndia from "../components/Pages/Region/BannerIndia";
const IndiaPage = () => {
  return (
    <Layout>
      <Seo title="MRC in India" url="https://migrantresilience.org/india" />
      <BannerIndia />
      <RegionMap
        title="States MRC is active in"
        image="/images/Migration Map_India 2.png"
      />
    </Layout>
  );
};
export default IndiaPage;
